import React, {Component} from "react";
import "./Board.css";
import {invokeApig} from "../libs/awsLib";
import img from "../images/hashimg.jpeg";
import deleteimg from "../images/deleteimg.jpeg";
import thumbsimg from "../images/thumbsup.png";
import {io} from "socket.io-client";

const socket = io("ws://ec2-3-83-251-17.compute-1.amazonaws.com:5000/");

export default class Board extends Component {
  constructor(props) {
    super(props);

    this.file = null;

    this.state = {
      board: null,
      boardId: this.props.match.params.id,
      boardItems:{},
      isNotesAdded:null,
      content: "",
      users : 1
    };
  }

  async componentDidMount() {
    try {
      await this.fetchItems();

      socket.emit("join-room",this.state.boardId);
      socket.on("board-users" , (usersCount)=>{
        this.setState({users: usersCount.users});
        console.log(this.state.users);
      });
      socket.on("editing", (itemId, status) => {
        console.log("got itemId editing:"+itemId+":"+status)
        if(status) {
          document.getElementById(itemId).style.backgroundColor = "#f5c0c091";
        }
        else {
          document.getElementById(itemId).style.backgroundColor = "#CBFAFF";
        }

      } );
    } catch (e) {
      alert(e);
    }
  }

    async fetchItems()
    {
      try {
        const results = await this.getItems();
        results[0].sort(function(a, b){
          return b.likes - a.likes;
        });
        this.setState({ boardItems: results[0]});
      } catch (e) {
        alert(e);
      }
      this.setState({ isNotesAdded: true });
    }

    async addNotes()  {
      let boardItemsTemp = this.state.boardItems;
      let key = Object.keys(this.state.boardItems).length;
      let itemIdNew = (Array.isArray(this.state.boardItems) && this.state.boardItems.length)?  Math.max(...this.state.boardItems.map( item => parseInt(item.itemId))) + 1 : 1;
      boardItemsTemp[key] = {"boardId": `${this.state.boardId}`,
                             "itemId": `${itemIdNew}`, "text":'Enter Idea', "likes":0};

      this.setState({
        isNotesAdded: true,
        boardItems: boardItemsTemp
      });
      try {
         await this.createItem(boardItemsTemp[key]).then(x=>x);
      }
      catch(e) {
        alert(e);
      }
    }

  async removeNotes(item)  {
    let boardItemsTemp = this.state.boardItems.filter( itemi=> itemi.itemId != item.itemId);

    this.setState({
      isNotesAdded: true,
      boardItems: boardItemsTemp
    });
    try {
      await this.deleteItem(item);
    }
    catch(e) {
      alert(e.stack);
    }
  }

    incrementLikes = async (boardItem) => {
      let boardItemsTemp = this.state.boardItems;
      let boardItemTemp = this.state.boardItems[boardItem.toString()];
      let likeCountIncrement = this.state.boardItems[boardItem.toString()].likes+1;
      boardItemTemp.likes=likeCountIncrement;
      try {
        await this.createItem(boardItemTemp);
      }
      catch(e) {
        alert(e);
      }

      boardItemsTemp[boardItem.toString()]= boardItemTemp;
      boardItemsTemp.sort(function(a, b){
        return b.likes - a.likes;
      });
      this.setState({
        isNotesAdded: true,
        boardItems: boardItemsTemp
      });

    }

    initItemBackground = async (item) => {
      if (item.editing == true && document.getElementById(item.itemId)!=null) {
        document.getElementById(item.itemId).style.backgroundColor = "#f5c0c091";
      }
    }

    updateItemText(item) {

    return async event => {
      event.preventDefault();
      item.text=event.target.value;
      item.editing = false;
      await this.updateItemAndEmit(item,false);
    };


  }

    updateItemAndEmit = async (item, status) => {
      item.editing = status;
      try {
         await this.createItem(item);
      }
      catch(e) {
        alert(e);
      }
      socket.emit("editing", item.itemId, status);
    }

    getItems() {
      return invokeApig({
        api: "items",
        path: `/board/${this.state.boardId}/item`,
        method: "GET"
      });
    }

    createItem(item) {
        return invokeApig({
          api: "items",
          path: `/board/${this.state.boardId}/item`,
          method: "POST",
          body: item
        });
      }

    deleteItem(item) {
      return invokeApig({
        api: "items",
        path: `/board/${this.state.boardId}/item/${item.itemId}`,
        method: "DELETE"
      });
    }


  renderNotes(){

    console.log(this.state.boardItems)

    const renderItems = Object.keys(this.state.boardItems).map((boardItem) =>

    <div  key={this.state.boardItems[boardItem.toString()].itemId} ref="sticky2" className="note note2">
    <img className="pin" src={img}/>{this.state.boardItems[boardItem.toString()].itemId}
      <img className="delete" src={deleteimg} onClick={() => this.removeNotes(this.state.boardItems[boardItem.toString()])}/>
    <textarea id={this.state.boardItems[boardItem.toString()].itemId} onLoad={this.initItemBackground(this.state.boardItems[boardItem.toString()])}
              onClick={()=>this.updateItemAndEmit(this.state.boardItems[boardItem.toString()], true)}
              onBlur={this.updateItemText(this.state.boardItems[boardItem.toString()]).bind(this)}
              className="sticky sticky2" defaultValue={this.state.boardItems[boardItem.toString()].text}></textarea>
    <img className="pin" src={thumbsimg} onClick={() => this.incrementLikes(boardItem)}/>
    <span>{this.state.boardItems[boardItem.toString()].likes}</span>
    <br/>
    </div>
  );

    return <div>{renderItems}</div>;
}

  render() {
    return (

        <div class="board">
          <div className="tags-wrapper">
            <div className="user-stats share">&nbsp; users ({this.state.users}) </div>
            <div className="user-stats">sowmya</div>
            <div className="user-stats share">invite <a href={()=>this.addUsers()}>+&nbsp;</a></div>
          </div>
          <div className="tags-wrapper">
            <div className="user-stats share">&nbsp; tags </div>
            <div className="user-stats">#tasks</div>
            <div className="user-stats">#ui</div>
            <div className="user-stats share">add<a href={()=>this.addTags()}>+&nbsp;</a></div>
          </div>
          <div class="main-container" >

          <button className="addnotes" type="button" onClick={()=>this.addNotes()} className="btn btn-default" >
          <span className="glyphicon glyphicon-plus" aria-hidden="true"></span>
          </button>

          <div className="notes">
          {this.state.isNotesAdded && this.renderNotes(this.state.boardItems)}

          </div>
            <br class="clear"/>
          </div>
        </div>


    );
  }


}
