import React from "react";
import { Route, NavLink } from "react-router-dom";
import {Dropdown, NavItem} from "react-bootstrap";

export default props =>
  <Route
    path={props.href}
    exact
    children={({ match, history }) =>
      <Dropdown id="nav-bar" class
        {...props}
        active={match ? true : false}
      >
          <Dropdown.Toggle variant="success" id="dropdown-basic">
              {props.children}
          </Dropdown.Toggle>

          <Dropdown.Menu>
              <NavItem
                  onClick={e => history.push(e.currentTarget.getAttribute("href"))}
                  {...props}
                  active={match ? true : false}
              >
                  Create a new Boat or Tag
              </NavItem>
              <NavItem
                  onClick={e => history.push(e.currentTarget.getAttribute("href"))}
                  {...props}
                  active={match ? true : false}
              >
                  Green Lovers (#vegetarian+#F30-35+#Kid10-12)
              </NavItem>
              <NavItem
                  onClick={e => history.push(e.currentTarget.getAttribute("href"))}
                  {...props}
                  active={match ? true : false}
              >
                  Vegan Angels (#vegan+#mom)
              </NavItem>

              <NavItem
                  onClick={e => history.push(e.currentTarget.getAttribute("href"))}
                  {...props}
                  active={match ? true : false}
              >
                  Gorgeous Geeks  (#engineer+#mom+#F20-45)
              </NavItem>
              <NavItem
                  onClick={e => history.push(e.currentTarget.getAttribute("href"))}
                  {...props}
                  active={match ? true : false}
              >
                  New Moms  (#infant#mom+#F20-45)
              </NavItem>
          </Dropdown.Menu>

      </Dropdown>
    }
  />;
