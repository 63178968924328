// for storing session info on local storage or cookies

import {CognitoUserPool} from "amazon-cognito-identity-js";
import config from "../config";
import AWS from "aws-sdk";

export async function authUser() {

  if (
    AWS.config.credentials &&
    Date.now() < AWS.config.credentials.expireTime - 60000
  ) {
    return true;
  }

  const currentUser = getCurrentUser();

  if (currentUser === null) {
    return false;
  }

    if (currentUser === null) {
      return false;
    }

    const userToken = await getUserToken(currentUser);

    await getAwsCredentials(userToken);

    return true;

}

export function signOutUser() {
  const currentUser = getCurrentUser();

  if(currentUser !== null) {
    currentUser.signOut();
  }
  
  if (AWS.config.credentials) {
    AWS.config.credentials.clearCachedId();
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({});
  }
}

function getUserToken(currentUser) {
  return new Promise((resolve, reject) => {
    currentUser.getSession(function(err,session) {
      if(err) {
        reject(err);
        return;
      }
      resolve(session.getIdToken().getJwtToken());
    });
  });
}

function getCurrentUser() {
  const userPool = new CognitoUserPool({
    UserPoolId: config.cognito.USER_POOL_ID,
    ClientId: config.cognito.APP_CLIENT_ID
  });
  return userPool.getCurrentUser();
}

function getAwsCredentials(userToken) {
  const authenticator = `cognito-idp.${config.cognito
    .REGION}.amazonaws.com/${config.cognito.USER_POOL_ID}`;

  AWS.config.update({ region: config.cognito.REGION });

  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: config.cognito.IDENTITY_POOL_ID,
    Logins: {
      [authenticator]: userToken
    }
  });

  return AWS.config.credentials.getPromise();
}

export async function invokeApig({
    api,
  path,
  method = "GET",
  headers = {},
  queryParams = {},
  body
}) {
  if (!await authUser()) {
    throw new Error("User is not logged in");
  }
// instead of signing , add header , just usertoken, userToken, getUserToken


  body = body ? JSON.stringify(body) : body;

  const userToken = await getUserToken(getCurrentUser());
  headers['Authorization']= userToken;
  headers['Content-Type']= "application/json";

  console.log("config.apiGateway:", config.apiGateway[api]);
  const results = await fetch(config.apiGateway[api].URL+path, {
    method,
    headers,
    body
  });

  if (results.status !== 200) {
    throw new Error(await results.text());
  }

  return results.json();
}
