export default {
  cognito: {
    USER_POOL_ID: "us-east-1_k3CjCLq76",
    APP_CLIENT_ID: "6315lpc5a5r4jqfqr57tddo485",
    IDENTITY_POOL_ID: "us-east-1:0d2ec0db-258b-4cce-ae9f-c09b9aff8ea2",
    REGION: "us-east-1"
  },
  apiGateway: {
  "boards": {URL: "https://kdxwr19rml.execute-api.us-east-1.amazonaws.com/prod",
  REGION: "us-east-1"
  },
  "items": {
    URL: "https://kdxlqxvzf9.execute-api.us-east-1.amazonaws.com/Prod",
    REGION: "us-east-1"
  }
}
};
