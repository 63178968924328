import React, { Component } from "react";
import "./ContactUs.css";

export default class ContactUs extends Component{

    render(){
      return(
        <div className="move-right">
        <ul>
        <li>Contact :  <span className="text-color"> help@momswhoplan.com </span>
        </li></ul></div>
      );
    }

}
