import React, { Component } from "react";
import "./Features.css";

export default class Features extends Component {
  render(){
    return (
      <div className="features">

      <div className="move-right">
        <p> </p>
      <ul>
      <li> Pay a professional or an AI to collaborate with you for a flat hourly rate. </li>

      </ul>
      </div>


        </div>

    );
  }
}
