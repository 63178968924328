import React from "react";
import { Route, Switch } from "react-router-dom";
import AppliedRoute from "./components/AppliedRoute";
import Home from "./containers/Home";
import Login from "./containers/Login";
import ForgotPassword from "./containers/ForgotPassword";
import Features from "./containers/Features";
import Signup from "./containers/Signup"
import NotFound from "./containers/NotFound";
import Boards from "./containers/Boards";
import Board from "./containers/Board";
import MPBoard from "./containers/MPBoard"
import TPBoard from "./containers/TPBoard"
import WPBoard from "./containers/WPBoard"
import SCBoard from "./containers/SCBoard"
import CPBoard from "./containers/CPBoard"
import Demo from "./containers/Demo";
import ContactUs from "./containers/ContactUs";

export default ({ childProps }) =>
  <Switch>
    <AppliedRoute path="/" exact component={Home} props={childProps} />
    <AppliedRoute path="/Login" exact component={Login} props={childProps} />
      <AppliedRoute path="/ForgotPassword" exact component={ForgotPassword} props={childProps} />
    <AppliedRoute path="/Features" exact component={Features} props={childProps} />
    <AppliedRoute path="/Signup" exact component={Signup} props={childProps} />
    <AppliedRoute path="/boards" exact component={Boards} props={childProps} />
    <AppliedRoute path="/ideate-board/:id" exact component={Board} props={childProps} />
      <AppliedRoute path="/meal-plan-board/:id" exact component={MPBoard} props={childProps} />
      <AppliedRoute path="/trip-plan-board/:id" exact component={TPBoard} props={childProps} />
      <AppliedRoute path="/workout-plan-board/:id" exact component={WPBoard} props={childProps} />
      <AppliedRoute path="/skincare-plan-board/:id" exact component={SCBoard} props={childProps} />
      <AppliedRoute path="/career-plan-board/:id" exact component={CPBoard} props={childProps} />
      <AppliedRoute path="/demo" exact component={Demo} props={childProps} />
      <AppliedRoute path="/contactus" exact component={ContactUs} props={childProps} />


    { /* Finally, catch all unmatched routes */ }
    <Route component={NotFound} />
  </Switch>;
