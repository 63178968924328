import React, {Component} from "react";
import "./TPBoard.css";
import {invokeApig} from "../libs/awsLib";
import img from "../images/hashimg.jpeg";
import deleteimg from "../images/deleteimg.jpeg";
import thumbsimg from "../images/thumbsup.png";
import {io} from "socket.io-client";

const socket = io("ws://ec2-3-83-251-17.compute-1.amazonaws.com:5000/");

export default class TPBoard extends Component {
  constructor(props) {
    super(props);

    this.file = null;

    this.state = {
      board: null,
      boardId: this.props.match.params.id,
      c1BoardItems:{},
      boardItems:{},
      isNotesAdded:null,
      content: "",
      users : 1,
      numberOfLanes : 5
    };
  }

  async componentDidMount() {
    try {
      await this.fetchItems();

      socket.emit("join-room",this.state.boardId);
      socket.on("board-users" , (usersCount)=>{
        this.setState({users: usersCount.users});
        console.log(this.state.users);
      });
      socket.on("editing", (itemId, status) => {
        console.log("got itemId editing:"+itemId+":"+status)
        if(status) {
          document.getElementById("i"+itemId).style.backgroundColor = "#f5c0c091";
          document.getElementById("d"+itemId).style.backgroundColor = "#f5c0c091";
        }
        else {
          document.getElementById("i"+itemId).style.backgroundColor = "#CBFAFF";
          document.getElementById("d"+itemId).style.backgroundColor = "#CBFAFF";

        }

      } );
    } catch (e) {
      alert(e);
    }
  }

    async fetchItems()
    {
      try {
        const results = await this.getItems();

        this.setState({ c1BoardItems: results });
      } catch (e) {
        alert(e);
      }
      this.setState({ isNotesAdded: true });
    }

    async addRecipeCardStack(laneNumber)  {
      let c1BoardItemsTemp = this.state.c1BoardItems;
      let laneBoardItems = this.state.c1BoardItems[laneNumber];
      let key = (laneBoardItems != null )? Object.keys(laneBoardItems).length : 0;
      let itemIdNew = (key == 0)?  1 : Math.max(...laneBoardItems.map( item => parseInt(item.itemId.substr(1)))) + 1;

      if(laneBoardItems == null ) c1BoardItemsTemp[laneNumber] = new Array();
      c1BoardItemsTemp[laneNumber][key] = {"boardId": `${this.state.boardId}`,
                             "itemId": `${laneNumber}${itemIdNew}`,"label":"Recipe", "text":'Ingredients',
                        "text2":'Directions', "likes":0, "laneId": laneNumber, "type" :"recipe-card" };

      this.setState({
        isNotesAdded: true,
        c1BoardItems: c1BoardItemsTemp
      });
      try {
         await this.createItem(c1BoardItemsTemp[laneNumber][key]).then(x=>x);
      }
      catch(e) {
        alert(e);
      }
    }

  async removeNotes(item)  {
    let c1BoardItemsTemp = this.state.c1BoardItems.filter( itemi=> itemi.itemId != item.itemId);

    this.setState({
      isNotesAdded: true,
      c1BoardItems: c1BoardItemsTemp
    });
    try {
      await this.deleteItem(item);
    }
    catch(e) {
      alert(e.stack);
    }
  }

    initItemBackground = async (item) => {
      if (item.editing == true && document.getElementById(item.itemId)!=null) {
        document.getElementById(item.itemId).style.backgroundColor = "#f5c0c091";
      }
    }
    updateItemLabel(item) {
    return async event => {
      event.preventDefault();
      item.label=event.target.value;
      item.editing = false;
      await this.updateItemAndEmit(item,false);
    };
  }
    updateItemText(item) {
    return async event => {
      event.preventDefault();
      item.text=event.target.value;
      item.editing = false;
      await this.updateItemAndEmit(item,false);
    };
  }
    updateItemText2(item) {
    return async event => {
      event.preventDefault();
      item.text2=event.target.value;
      item.editing = false;
      await this.updateItemAndEmit(item,false);
    };
  }

    updateItemAndEmit = async (item, status) => {
      item.editing = status;
      try {
         await this.createItem(item);
      }
      catch(e) {
        alert(e);
      }
      socket.emit("editing", item.itemId, status);
    }

    getItems() {
      return invokeApig({
        api: "items",
        path: `/board/${this.state.boardId}/item`,
        method: "GET"
      });
  }

    createItem(item) {
        return invokeApig({
          api: "items",
          path: `/board/${this.state.boardId}/item`,
          method: "POST",
          body: item
        });
      }

    deleteItem(item) {
      return invokeApig({
        api: "items",
        path: `/board/${this.state.boardId}/item/${item.itemId}`,
        method: "DELETE"
      });
    }


  renderCardStack(laneNumber){

    const laneBoardItems = this.state.c1BoardItems[laneNumber];
    const renderItems = laneBoardItems != null && Object.keys(laneBoardItems).map((boardItem) =>


    <div  key={laneBoardItems[boardItem.toString()].itemId} ref="recipeCard" className="recipeCard">

      <div className="grid-item1">
     <textarea id={"l"+laneBoardItems[boardItem.toString()].itemId} onLoad={this.initItemBackground(laneBoardItems[boardItem.toString()])}
               onClick={()=>this.updateItemAndEmit(laneBoardItems[boardItem.toString()], true)}
               onBlur={this.updateItemLabel(laneBoardItems[boardItem.toString()]).bind(this)}
               className="recipeCard part0" defaultValue={laneBoardItems[boardItem.toString()].label}></textarea>
      </div>
      <div className="grid-item2">
     <textarea id={"i"+laneBoardItems[boardItem.toString()].itemId} onLoad={this.initItemBackground(laneBoardItems[boardItem.toString()])}
              onClick={()=>this.updateItemAndEmit(laneBoardItems[boardItem.toString()], true)}
              onBlur={this.updateItemText(laneBoardItems[boardItem.toString()]).bind(this)}
              className="recipeCard part1" defaultValue={laneBoardItems[boardItem.toString()].text}></textarea>
      </div>
      <div className="grid-item3">
        <textarea id={"d"+laneBoardItems[boardItem.toString()].itemId} onLoad={this.initItemBackground(laneBoardItems[boardItem.toString()])}
                onClick={()=>this.updateItemAndEmit(laneBoardItems[boardItem.toString()], true)}
                onBlur={this.updateItemText2(laneBoardItems[boardItem.toString()]).bind(this)}
                className="recipeCard part2" defaultValue={laneBoardItems[boardItem.toString()].text2}></textarea>
    </div>

    </div>
  );

    return <div className="notes">{renderItems}</div>;
}

  renderLane(laneNumber, laneLabel){
    return (
        <div className="lane-container">
        <div className="wrapper">
          <button className="addnotes" type="button" onClick={() => this.addRecipeCardStack(laneNumber)}
                  className="btn btn-default" aria-label="Left Align">
            <span className="glyphicon glyphicon-plus" aria-hidden="true"></span>


          </button>
          <div className="row-label">{laneLabel}</div>
          {this.state.isNotesAdded && this.renderCardStack(laneNumber)}
        </div>
          <br className="clear"/>
        </div>
    );
}
  render() {
    return (

        <div class="board">
          <div className="tags-wrapper">
          <div class ="user-stats">&nbsp;&nbsp;{this.state.users} user active <a href={""}>...</a> </div>
          <div className="user-stats">#Family4 </div>
          <div className="user-stats">#S11 </div>
            <div className="user-stats">#S5 </div>
            <div className="user-stats">#lowcarb </div>
            <div className="user-stats">#nobeaf </div>
            <div className="user-stats">#chesterfield-mo </div>
          </div>
            <div class="main-container" >
            {this.renderLane(1, "Hotel")}
            {this.renderLane(2, "Places to eat")}
            {this.renderLane(3, "Places to go")}
            {this.renderLane(4, "Places to shop")}
            {this.renderLane(5, "Checklist")}

          </div>
        </div>
    );
  }


}
