import React, {Component} from "react";
import {Form, FormControl, FormGroup, ListGroup, ListGroupItem, PageHeader} from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import "./Boards.css";
import {invokeApig} from "../libs/awsLib";
import img from "../images/hashimg.jpeg";
import deleteimg from "../images/delete.png";


export default class NewBoard extends Component {


  constructor(props) {
    super(props);

    this.name =  "";
    this.state = {
      isLoading:null,
      boardName: "",
      type:"ideate",
      boards: []
    };
;

  }


  validateForm() {
    return this.state.boardName.length >0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSubmit = async event => {
    event.preventDefault();
    this.setState({isLoading: true});
    try {
      await this.createBoard({
        boardName: this.state.boardName,
        type: this.state.type
      });
      this.getBoards();
      this.setState({isLoading: false});
      this.props.history.push("/boards");

    } catch (e) {
      alert(e);
      this.setState({isLoading: false});
    }
  }

  setAccessValue(board){
    if(document.getElementById(board.boardid) != null) {
      if(board.access =='public')
      document.getElementById(board.boardid).checked = true;
    }
  }

  handleAccessChange(board){
    let checked = document.getElementById(board.boardid);
    let accessUpdated = (checked.checked)? "public" : "private";
    this.saveBoardAccess({
      userid: board.userid,
      boardid: board.boardid,
      boardName: board.boardName,
      type: this.state.type,
      access: accessUpdated
    });
  }

  deleteBoard(board){
    invokeApig({
      api: "boards",
      path: `/boards/${board.boardid}`,
      method: "DELETE",
      body: board
    });
    let tempResults = this.state.boards.filter(bd=> bd.boardid != board.boardid);
    this.setState({ boards:tempResults});
  }

  saveBoardAccess(board){
    return invokeApig({
      api: "boards",
      path: `/boards/${board.boardid}`,
      method: "PUT",
      body: board
    });
  }

  createBoard(board) {
    return invokeApig({
      api: "boards",
      path: "/boards",
      method: "POST",
      body: board
    });
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }
    this.getBoards();
  }

  async getBoards()
  {
    try {
      const results = await this.boards();
      this.setState({ boards: results });
    } catch (e) {
      alert(e);
    }

    this.setState({ isLoading: false });
  }

  boards() {
    return invokeApig({ api: "boards", path: "/boards" });
  }

  renderBoardsList(boards) {

    return [{}].concat(boards).map(
    (board, i) =>
      i !== 0
        ? <div><ListGroupItem
            key={board.boardid}
            href={`/${board.type}-board/${board.boardid}`}
            onClick={this.handleboardClick}
            header={board.boardName}
          >
            {board.type}  &nbsp; &nbsp;
            {"  " + new Date(board.createdAt).toDateString()}&nbsp; &nbsp;public &nbsp;
            <input type="checkbox" onLoad={this.setAccessValue(board)}  id={board.boardid} name="access" onClick = {()=>this.handleAccessChange(board)}/>


          </ListGroupItem>

          <img className="deleteboard" src={deleteimg} onClick={()=>this.deleteBoard(board)}/></div>:<div> </div>

  );
}


  render() {

    return (
      <div>
     <div className="NewBoard">
       <Form inline onSubmit={this.handleSubmit}>
         <FormGroup controlId="boardName">
           <FormControl
             onChange={this.handleChange}
             value={this.state.boardName}
            placeholder="Enter Board name"
             type="text"
           />
         </FormGroup>
         <FormGroup controlId="type">
        <FormControl componentClass="select"  onChange={this.handleChange}
                      value={this.state.type}>
          <option value="ideate">Ideate Board</option>
          <option value="meal-plan">Meal Plan Board</option>
          <option value="trip-plan" >Trip Plan Board</option>
          <option value="workout-plan" >Workout Plan Board</option>
          <option value="skincare-plan" >Skin Care Routine Board</option>
          <option value="investment-plan" >Investment Board</option>
          <option value="career-plan" >Career Plan Board</option>
          <option value="white" disabled>White Board</option>
        </FormControl>
         </FormGroup>

         <LoaderButton
           bsStyle="primary"
           bsSize="large"
           disabled={!this.validateForm()}
           type="submit"
           isLoading={this.state.isLoading}
           text="Create"
           loadingText="Creating…"
         />
       </Form>
     </div>
     <div className="boards">
       <PageHeader>Your Boards</PageHeader>
       <ListGroup>
         {!this.state.isLoading && this.renderBoardsList(this.state.boards)}
       </ListGroup>
     </div>
     </div>

   );
  }
}
