import React, { Component } from "react";
import {  FormControl, FormGroup, ControlLabel } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import "./Login.css";
import config from "../config";
import {
  CognitoUserPool,
  AuthenticationDetails,
  CognitoUser
} from "amazon-cognito-identity-js";

export default class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      email: "",
      code: "",
      password: "",
      confirmPassword: "",
      stage: 1
    };
  }

  validateForm() {
    return this.state.email.length > 0 ;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSubmit = async event => {
    event.preventDefault();
    this.setState({ isLoading:true });

    try{
      await this.sendCode(this.state.email);
      this.props.history.push("/ForgotPassword");
    } catch(e) {
      alert(e);
      this.setState({ isLoading:false });
    }
  }

  handleResetPassword = async event => {
    event.preventDefault();
    this.setState({ isLoading:true });

    try{
      await this.resetPassword(this.state.code, this.state.password, this.state.confirmPassword);
      this.props.history.push("/ForgotPassword");
    } catch(e) {
      alert(e);
      this.setState({ isLoading:false });
    }
  }

  render() {
    return (
        <div>
        { this.state.stage === 1 &&  (
      <div className="Login">
        <form onSubmit={this.handleSubmit}>
        <FormGroup controlId="email" bsSize="large">
          <ControlLabel>Email</ControlLabel>
          <FormControl autoFocus type="email"
          value={this.state.email}
          onChange={this.handleChange} />
          </FormGroup>

            <LoaderButton block bsSize="large"
            disabled={!this.validateForm()}
            type="submit"
            isLoading={this.state.isLoading}
            text="Reset Password"
                          loadingText="Sending reset request"/></form>
      </div>  ) }

          { this.state.stage === 2 &&  (
              <div className="Login">
                <form onSubmit={this.handleResetPassword}>
                  <FormGroup controlId="code" bsSize="large">
                    <ControlLabel>verification code</ControlLabel>
                    <FormControl autoFocus type="text"
                                 value={this.state.code}
                                 onChange={this.handleChange} />
                  </FormGroup>

                  <FormGroup controlId="password" bsSize="large">
                    <ControlLabel>Password</ControlLabel>
                    <FormControl type="password"
                                 value={this.state.password}
                                 onChange={this.handleChange} />
                  </FormGroup>

                  <FormGroup controlId="confirmPassword" bsSize="large">
                    <ControlLabel>Password</ControlLabel>
                    <FormControl type="password"
                                 value={this.state.password}
                                 onChange={this.handleChange} />
                  </FormGroup>
                  <LoaderButton block bsSize="large"
                                disabled={!this.validateForm()}
                                type="submit"
                                isLoading={this.state.isLoading}
                                text="Create new password"
                                loadingText="Sending reset request"/></form>
              </div>  ) }
        </div>
      );
    }

    async sendCode(email) {
      const userPool = new CognitoUserPool({
        UserPoolId: config.cognito.USER_POOL_ID,
        ClientId: config.cognito.APP_CLIENT_ID
      });
      const user = new CognitoUser({ Username: email, Pool:userPool });
      user.forgotPassword({
        onSuccess: data =>{
          console.log("success"+data);
        },
        onFailure: data =>{
          console.log("failure"+data);
        },
        inputVerificationCode: data => {
          console.log("verify"+data);
          this.setState({stage:2, isLoading: false});
        }
      }
      );
    }

    async resetPassword(code, password){
      const userPool = new CognitoUserPool({
        UserPoolId: config.cognito.USER_POOL_ID,
        ClientId: config.cognito.APP_CLIENT_ID
      });
      const user = new CognitoUser({ Username: this.state.email, Pool:userPool });
      user.confirmPassword(code, password,{
            onSuccess: data =>{
              console.log("success"+data);
            },
            onFailure: data =>{
              console.log("failure"+data);
            }

          }
      );
    }
}
