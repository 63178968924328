import React from "react";
import { Route, NavLink } from "react-router-dom";
import {Dropdown, NavItem} from "react-bootstrap";

export default props =>
  <Route
    path={props.href}
    exact
    children={({ match, history }) =>
      <Dropdown id="nav-bar" class
        {...props}
        active={match ? true : false}
      >
          <Dropdown.Toggle variant="success" id="dropdown-basic">
              {props.children}
          </Dropdown.Toggle>

          <Dropdown.Menu>

              <NavItem
                  onClick={e => history.push(e.currentTarget.getAttribute("href"))}
                  {...props} href="/newcat"
                  active={match ? true : false}
              >
                  Create a new category
              </NavItem>
              <NavItem
                  onClick={e => history.push(e.currentTarget.getAttribute("href"))}
                  {...props} href="/boards"
                  active={match ? true : false}
              >
                  All Kollective Boards
              </NavItem>
              <NavItem
                  onClick={e => history.push(e.currentTarget.getAttribute("href"))}
                  {...props} href="/ideate2"
                  active={match ? true : false}
              >
                  Ideate app.
              </NavItem>
              <NavItem
                  onClick={e => history.push(e.currentTarget.getAttribute("href"))}
                  {...props} href="/top10s"
                  active={match ? true : false}
              >
                  Top 10s
              </NavItem>
              <NavItem
                  onClick={e => history.push(e.currentTarget.getAttribute("href"))}
                  {...props} href="/mpboards"
                  active={match ? true : false}
              >
                  Mealplans
              </NavItem>
              <NavItem
                  onClick={e => history.push(e.currentTarget.getAttribute("href"))}
                  {...props} href="/tripboards"
                  active={match ? true : false}
              >
                  Tripplans
              </NavItem>
              <NavItem
                  onClick={e => history.push(e.currentTarget.getAttribute("href"))}
                  {...props} href="/fitboards"
                  active={match ? true : false}
              >
                  Fitness Routines
              </NavItem>
              <NavItem
                  onClick={e => history.push(e.currentTarget.getAttribute("href"))}
                  {...props} href="/skinboards"
                  active={match ? true : false}
              >
                  Skin care Routines
              </NavItem>
              <NavItem
                  onClick={e => history.push(e.currentTarget.getAttribute("href"))}
                  {...props} href="/checklists"
                  active={match ? true : false}
              >
                  Checklists
              </NavItem>
              <NavItem
                  onClick={e => history.push(e.currentTarget.getAttribute("href"))}
                  {...props} href="/next"
                  active={match ? true : false}
              >
                  More.. (235)
              </NavItem>

          </Dropdown.Menu>

      </Dropdown>
    }
  />;
