import React, {Component} from "react";
import "./Home.css";

export default class Home extends Component {

    static props = {

        width: 520,
        height: 200,
    };


    render() {
        return (
            <div className="Home">
                <div className="lander">
                    <div className="text-block">

                        <div className="text-font">Co-ideate, co-create, co-hire <span className="text-color">experts </span> or
                            <span className="text-color"> AI assistant</span>.
                        </div>
                        <br/>
                        <div className="text-font"> Discover or create
                            actionable  <span className="text-color">Kollectives (Boards) </span>.
                        </div>
                        <div className="text-font">Find <span className="text-color">Kollectives (Groups/Sessions)</span> or get matched with our AI algorithm
                             to start collaborating in realtime.
                        </div>


                        <br/>
                    </div>

                    <div className="text-block2">

                        <br/>

                    </div>
                </div>

            </div>
        );
    }
}
